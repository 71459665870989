import { Box, Card, Skeleton, Stack } from '@mui/material';
import CardShimmerEffect from './card-shimmer-effect';

export default function AlertSummaryShimmerEffect() {
  return (
    <Stack direction="row">
      {/* Main content area */}
      <Box sx={{ minWidth: 'calc(100% - 360px)', width: '100%' }}>
        <Box sx={{ maxWidth: '740px', width: '90%', margin: '0 auto', mt: 5 }}>
          {/* Title */}
          <Skeleton variant="text" width="100%" height={40} sx={{ mb: 2 }} />

          {/* Chips row */}
          <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
            <Skeleton variant="rectangular" width={120} height={32} />
            <Skeleton variant="rectangular" width={100} height={32} />
            <Skeleton variant="rectangular" width={140} height={32} />
          </Stack>

          {/* Main content card */}
          <CardShimmerEffect />

          {/* Comments section */}
          <Box sx={{ mt: 3 }}>
            <Skeleton variant="text" width={150} height={32} />
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Skeleton variant="rectangular" width="100%" height={80} />
              <Skeleton variant="rectangular" width="100%" height={80} />
            </Stack>
          </Box>
        </Box>
      </Box>

      {/* Right sidebar */}
      <Box
        sx={{
          width: '360px',
          borderLeft: '1px solid #919EAB1F',
          flexShrink: 0,
          pt: 4,
          px: 3,
        }}
      >
        {/* Collaborators section */}
        <Stack spacing={2}>
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Stack>

        {/* Details section */}
        <Stack spacing={2}>
          <Skeleton variant="text" width={150} height={24} />
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Stack>

        {/* Recommended section */}
        <Stack spacing={2}>
          <Skeleton variant="text" width={180} height={24} />
          <Skeleton variant="rectangular" width="100%" height={120} />
        </Stack>
      </Box>
    </Stack>
  );
}
