import { useEffect, useState } from 'react';
import { IconButton, MenuList, MenuItem, Divider, Box, Checkbox } from '@mui/material';
import { CustomPopover } from 'src/components/custom-popover';
import { CONFIG } from 'src/config-global';
import { Iconify } from 'src/components/iconify';
import CommonSearch from '../../core/comman-search';
import { toast } from 'sonner';
import axiosInstance from 'src/utils/axios';
import useAlertStore from 'src/zustand-store/alert-store/alert-store';
import { useBoolean } from 'src/hooks/use-boolean';
import CommonShareBtn from '../comman-share-btn';

export default function AlertHeader({ currentNav, backButton }) {
  const { alertSummary, updateFilters, filters } = useAlertStore();
  const stared = useBoolean(alertSummary?.star || false);
  const [searchValue, setSearchValue] = useState(filters?.name || '');
  const read = useBoolean(alertSummary?.read || false);
  useEffect(() => {
    stared.setValue(alertSummary?.star || false);
    read.setValue(alertSummary?.read || false);
  }, [alertSummary]);
  const handleChange = async (url) => {
    const isChecked = url === 'star' ? !stared.value : !read.value;
    url == 'star' ? stared.onToggle() : read.onToggle();
    const endpoint = `alerts/${alertSummary.id}/${url}`;
    try {
      const response = await axiosInstance({
        method: isChecked ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        toast.success(response.data?.message || 'Star status updated successfully');
      } else {
        url == 'star' ? stared.onToggle() : read.onToggle();
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating star status:', error);
      url == 'star' ? stared.onToggle() : read.onToggle();

      toast.error(error.response?.data?.message || 'Failed to update star status');
    }
  };

  const handleSelection = (item) => {
    console.log(item);
  };

  const handleSearch = (value) => {
    updateFilters({ name: value });
  };
  useEffect(() => {
    setSearchValue(filters?.name || '');
  }, [filters?.name]);

  return (
    <>
      {!backButton && <CommonSearch handleSearch={handleSearch} initialValue={searchValue} />}
      {backButton && (
        <Checkbox
          key="read"
          icon={<Iconify color="#919EAB" icon="mdi:email-outline" />}
          checkedIcon={<Iconify color="#919EAB" icon="mdi:email-open-outline" />}
          checked={read.value}
          onChange={() => {
            handleChange('read');
          }}
          sx={{ p: 0.75 }}
        />
      )}
      {backButton && (
        <Checkbox
          color="warning"
          key="star"
          icon={<Iconify color="#919EAB" icon="eva:star-outline" />}
          checkedIcon={<Iconify icon="eva:star-fill" />}
          checked={stared.value}
          onChange={() => {
            handleChange('star');
          }}
          sx={{ p: 0.75 }}
        />
      )}

      <CommonShareBtn handleSelection={handleSelection} />
    </>
  );
}
