import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { CustomPopover } from 'src/components/custom-popover';
import { Iconify } from 'src/components/iconify';
import { CONFIG } from 'src/config-global';
import { useChatStore } from 'src/zustand-store/chat-store/chat-store';
import CommonShareBtn from '../comman-share-btn';

export default function ChatHeader({ currentNav, backButton }) {
  const {
    isExpanded,

    resetChat,
  } = useChatStore();

  const handleSelection = (item) => {
    console.log(item);
  };

  return (
    <Box
    // sx={{
    //     py: 2,
    //     px: 2.5,
    //     display: 'flex',
    //     alignItems: 'center',
    //     gap: 1,
    //     borderBottom: `1px solid ${theme.palette.divider}`,
    //     backgroundColor: '#fbfbfb'
    // }}
    >
      <Button
        variant="soft"
        color="inherit"
        size="small"
        startIcon={<Iconify icon="mingcute:add-line" />}
        onClick={() => resetChat()}
      >
        New Chat
      </Button>
      {/*  <Checkbox
        color="warning"
        icon={<Iconify color="#919EAB" icon="eva:star-outline" />}
        checkedIcon={<Iconify icon="eva:star-fill" />}
        sx={{ p: 0.75 }}
      />
      <Checkbox
        icon={<Iconify color="#919EAB" icon="mingcute:fullscreen-2-line" />}
        checkedIcon={<Iconify color="#919EAB" icon="mingcute:fullscreen-exit-2-line" />}
        checked={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{ p: 0.75 }}
      /> */}
      {/* <Iconify
             
                icon={!isExpanded ? 'ic:round-close-fullscreen' : 'mi:expand'}
                sx={{ px: 0.75, width: 48, height: 48, cursor: 'pointer', }}
                onClick={() => setIsExpanded(!isExpanded)}
            /> */}
      {/* <StyledDivider /> */}
      {/* 
            <Box sx={{ flexGrow: 1 }} /> */}

      <CommonShareBtn handleSelection={handleSelection} />
    </Box>
  );
}
