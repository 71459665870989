import { useEffect, useState } from 'react';
import { Button, Checkbox } from '@mui/material';
import { Iconify } from 'src/components/iconify';
import CommonSearch from '../../core/comman-search';
import { toast } from 'sonner';
import axiosInstance from 'src/utils/axios';
import useLawLibraryStore from 'src/zustand-store/law-library-store/law-library-store';
import { useBoolean } from 'src/hooks/use-boolean';
import CommonShareBtn from '../comman-share-btn';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export default function LawLibraryHeader({ currentNav, backButton, isInternalPolicies = false }) {
  const router = useRouter();

  const { dataSummary, updateFilters, filters } = useLawLibraryStore();
  const stared = useBoolean(dataSummary?.star || false);
  const [searchValue, setSearchValue] = useState(filters?.name || '');
  console.log(currentNav);
  useEffect(() => {
    stared.setValue(dataSummary?.star || false);
  }, [dataSummary]);

  const handleChange = async () => {
    const isChecked = !stared.value;
    stared.onToggle();
    const endpoint = `policies/${dataSummary.id}/star`;
    try {
      const response = await axiosInstance({
        method: isChecked ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        toast.success(response.data?.message || 'Star status updated successfully');
      } else {
        stared.onToggle();
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating star status:', error);
      stared.onToggle();
      toast.error(error.response?.data?.message || 'Failed to update star status');
    }
  };
  const openPDF = () => {
    router.push(`${paths.dashboard.lawLibrary}/pdf/${dataSummary.id}`);
  };
  const handleSelection = (item) => {
    console.log(item);
  };

  const handleSearch = (value) => {
    updateFilters({ name: value });
  };

  useEffect(() => {
    setSearchValue(filters?.name || '');
  }, [filters?.name]);

  return (
    <>
      {!backButton && <CommonSearch handleSearch={handleSearch} initialValue={searchValue} />}
      {backButton && !currentNav?.pathname.includes('pdf') && (
        <>
          {' '}
          <Checkbox
            color="warning"
            key="star"
            icon={<Iconify color="#919EAB" icon="eva:star-outline" />}
            checkedIcon={<Iconify icon="eva:star-fill" />}
            checked={stared.value}
            onChange={handleChange}
            sx={{ p: 0.75 }}
          />
          <Button
            disableRipple
            color="inherit"
            onClick={openPDF}
            startIcon={<Iconify icon="eva:file-text-outline" />}
            sx={{
              paddingLeft: '16px',
              paddingRight: '16px',
              py: 1,
              px: 2,
              backgroundColor: 'rgba(145, 158, 171, 0.08)',
              fontFamily: 'Inter',
              fontSize: 'var(--componentsbuttonsmallsize)',
              fontWeight: 500,
              lineHeight: 'var(--componentsbuttonsmallline-height)',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              '&:hover': {
                backgroundColor: 'rgba(145, 158, 171, 0.16)',
              },
            }}
          >
            View Legislation Doc
          </Button>
        </>
      )}
      <CommonShareBtn handleSelection={handleSelection} />
    </>
  );
}
