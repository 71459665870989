import { CONFIG } from 'src/config-global';

export const FILE_TYPE_OPTIONS = [['folder'], ['txt'], ['zip']];

export const ALERT_TYPE = ['type1', 'type2', 'type3'];
export const DOCS_TYPE = ['NEWS', 'BLOG'];
export const SOURCE_TYPE = ['FCA', 'EBA', 'BCA'];
export const TOPICS_TYPE = ['FCA', 'EBA', 'BCA'];
export const TABS_FILTER_TYPE = ['Pending', 'Assigned', 'All', 'Starred'];
export const HEADING_TEXT = [
  'You will see all unassigned & pending alerts here',
  'You will see all assigned & pending alerts here',
  'You will see all alerts here',
  'You will see all alerts which you or your team has marked as star here',
];
export const ALERT_FLITER_LABEL = [
  'Alert Type',
  'Doc Type',
  'Sources',
  ...(CONFIG.featureToggle.alert.filter.topics ? ['Topics'] : []),
];
export const ALERTS_TYPE_OPTIONS = [ALERT_TYPE, DOCS_TYPE, SOURCE_TYPE, TOPICS_TYPE];
export const TABS = TABS_FILTER_TYPE.map((val, i) => ({
  value: val,
  label: val,
  headingText: HEADING_TEXT[i],
}));
export const GAP_ANALYSIS_FLITER_LABEL = ['Risk Level', 'Impacted Area', 'Owner', 'Task Status'];
export const IMPACT_FLITER_LABEL = ['Product Impacted', 'Business Impacted'];
export const RESPONSE_REQUIRED_LABEL = ['informative', 'actionable'];

export const DOCUMENT_TYPE_REGULATION = [
  'Guidance',
  'Directives',
  'Legislation',
  'Regulations',
  'Regulatory Filings',
];
export const DOCUMENT_TYPE_UPDATE = [
  'Bills',
  'Proposed Rulemaking',
  'Consultation',
  'Discussion Paper',
  'Enforcements',
  'Events',
  'Letters and Comments',
  'Industry standard',
  'News',
  'Notice and circular',
  'Reports',
  'Research and Papers',
  'Others',
];

export const ALERT_TOPICS = [
  'Accounting and auditing',
  'Anti-Bribery/Corruption',
  'Anti-Money Laundering and Countering the Financing of Terrorism',
  'Competition and Antitrust',
  'Blockchain/Crypto',
  'Consumer protection',
  'Corporate Governance',
  'Credit risk',
  'Cybersecurity',
  'Data Governance',
  'Data Protection',
  'Dispute and Litigation',
  'Fraud and Security',
  'Lending',
  'Passporting and supervision of branches',
  'Payment services and electronic money',
  'Privacy',
  'Regulatory Reporting',
  'Sanctions',
  'Supervision',
  'Others',
];
export const READ_UNREAD = ['Read', 'Unread'];
export const HORIZON_NAMES = [...DOCUMENT_TYPE_REGULATION, ...DOCUMENT_TYPE_UPDATE];

export const DATE_RANGE_OPTIONS = [
  { value: 'Today', label: 'Today' },
  { value: 'Last 7 days', label: 'Last 7 days' },
  { value: 'Last 14 days', label: 'Last 14 days' },
  { value: 'Last 30 days', label: 'Last 30 days' },
  { value: 'Last 3 months', label: 'Last 3 months' },
  { value: 'Last 6 months', label: 'Last 6 months' },
  { value: 'Choose custom date range', label: 'Choose custom date range' },
];

export const IMPACT_AREAS_FLITER_LABEL = [
  'Product',
  'Engineering',
  'Compliance',
  'Security',
  'Fincrime',
  'Finance',
  'Business',
  'Corporate structure',
  'IT Infrastructure',
  'Operations',
];
export const IMPACT_PRODUCT_STATUS_FLITER_LABEL = [
  'Acquiring',
  'Bank Accounts',
  'Business lending',
  'Card Networking system',
  'Cash-Based Payment Methods',
  'Central bank Digital Currencies (CBDC)',
  'Clearing and settlement infrastructure',
  'Consumer Lending',
  'Cross-Border Payments',
  'Crypto-Assets',
  'E-Money',
  'Issuing',
  'Insurance Mediation',
  'Loyalty Schemes',
  'Mobile Payments',
  'Payment Processors',
  'Prepaid',
  'Remittances',
  'Third-party Providers',
];
export const IMPACTED_TABS_FLITER_LABEL = ['Pending', 'Reviewed', 'Starred', 'All'];
export const REQUIREMENT_TABS = IMPACTED_TABS_FLITER_LABEL.map((val, i) => ({
  value: val,
  label: val,
}));
export const LAW_LIBRARY_TABS = ['All', 'Starred'];

export const LAW_LIBRARY_TABS_MAP = [
  { value: 'All', label: 'All' },
  { value: 'Starred', label: 'Starred' },
];
